import React, { useState } from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'

import _ from 'lodash'

// CSS
import './Services.scss'

const Services = () => {
  const {
    diensten: { edges: dienstenList },
  } = useStaticQuery(
    graphql`
      {
        diensten: allWordpressWpDiensten {
          edges {
            node {
              id
              title
              path
              acf {
                logo_dienst {
                  localFile {
                    publicURL
                  }
                }
              }
            }
          }
        }
      }
    `
  )
  return (
    <div className="row services justify-content-lg-center pl-lg-5">
      {_.map(dienstenList, (dienst, index) => (
        <Link
          key={dienst.node.id}
          to={dienst.node.path}
          className="col-12 col-sm-6 mb-4 pb-2"
        >
          <div className={`box box-${index}`}>
            <div className="inner">
              <img src={dienst.node.acf.logo_dienst.localFile.publicURL} height="45" alt="" />
              <div
                className="inner-text"
                dangerouslySetInnerHTML={{ __html: dienst.node.title }}
              />
            </div>
          </div>
        </Link>
      ))}
    </div>
  )
}

export default Services
