import React, { useState } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import SimpleReactLightbox from 'simple-react-lightbox'
import { SRLWrapper } from 'simple-react-lightbox'

import _ from 'lodash'

import { TitleAlt } from 'components/Elements'

// CSS
import './Projects.scss'

const Projects = ({ categoryIds, limit, showHasMore }) => {
  const {
    projecten: { edges: projectenList },
  } = useStaticQuery(
    graphql`
      {
        projecten: allWordpressWpProjectenCrookewit(
          sort: { order: ASC, fields: date }
          filter: { categories: { elemMatch: { slug: { eq: "projecten" } } } }
        ) {
          edges {
            node {
              id
              title
              acf {
                projects_images {
                  gallery_images {
                    localFile {
                      childImageSharp {
                        fluid(maxWidth: 350, quality: 100) {
                          ...GatsbyImageSharpFluid_withWebp_noBase64
                        }
                      }
                      url
                    }
                    title
                  }
                  featured_image {
                    localFile {
                      childImageSharp {
                        fluid(maxWidth: 350, quality: 100) {
                          ...GatsbyImageSharpFluid_withWebp_noBase64
                        }
                      }
                      url
                    }
                    title
                  }
                }
              }
              categories {
                name
                wordpress_id
              }
            }
          }
        }
      }
    `
  )

  let [timesLimit, setTimesLimit] = useState(1)

  let tempPosts = projectenList

  limit = limit * timesLimit

  if (_.size(categoryIds) > 0) {
    tempPosts = _.filter(tempPosts, ({ node: { categories } }) => {
      let hasLabel = false

      _.each(categories, ({ wordpress_id: categoryId }) => {
        if (_.indexOf(categoryIds, categoryId) !== -1) hasLabel = true
      })

      return hasLabel
    })
  }

  let posts = limit
    ? _.filter(tempPosts, (edge, index) => index < limit)
    : tempPosts
  const hasMore = _.size(posts) < _.size(tempPosts)

  // Create an object with the options that we want to use
  const options = {
    showCaption: false,
    showThumbnails: true,
  }
  return (
    <div className="row projects">
      {_.map(posts, ({ node }) => (
        <SimpleReactLightbox key={node.id}>
          <div className="col-md-4 mb-4 p-0 d-flex justify-content-center">
            <SRLWrapper options={options}>
              <div className="inner">
                {_.map(node.acf.projects_images.gallery_images, (imageUrl, index) => (
                  <div key={index}>
                    <a href={imageUrl.localFile.url} data-attribute="SRL">
                      <img
                        className="image-project"
                        src={imageUrl.localFile.url}
                        alt={imageUrl.title}
                      />
                    </a>
                  </div>
                ))}
              </div>
            </SRLWrapper>
          </div>
        </SimpleReactLightbox>
      ))}
      {_.size(posts) === 0 && (
        <div className="mt-5 mx-4 text-center w-100">
          <TitleAlt className="w-100">
            Geen project(en) gevonden met gekozen filters
          </TitleAlt>
          <p className="mt-3">Probeer een andere filter!</p>
        </div>
      )}
    </div>
  )
}

export default Projects
