import React, { useState } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'
import { Breadcrumb } from 'gatsby-plugin-breadcrumb'

import _ from 'lodash'

import 'styles/ProjectenPage.scss'

// Components
import {
  TitleDefault,
  ContentDefault,
  BannerDefault,
  BreadCrumbBar,
  ButtonLineDefault,
} from 'components/Elements'
import Layout from 'components/Layout'
import Services from 'components/Services'
import SEO from 'components/SEO'
import Projects from 'components/Projects'

function ProjectenPage({ data, location, pageContext }) {
  const { wordpressPage: page } = data
  const { allWordpressCategory: categories } = data
  const {
    breadcrumb: { crumbs },
  } = pageContext

  let selected = []
  let category = location.hash

  if (_.size(category) > 0) {
    category = category.replace('#', '')

    if (category) {
      let selectedCategory = _.find(
        categories,
        ({ node: { slug } }) => slug.toLowerCase() === category.toLowerCase()
      )

      if (_.size(selectedCategory) > 0) {
        selected.push(selectedCategory.node.wordpress_id)
      }
    }
  }

  const [filters, setFilters] = useState({
    tempSearch: '',
    search: '',
    selected: selected,
  })

  if (_.size(selected) > 0) {
    filters.selected = selected
    window.location.hash = ''
  }

  return (
    <Layout>
      <SEO yoast={{ meta: page.yoast_meta }} />
      <div className="projecten-page">
        <BannerDefault className="banner-detail">
          <div className="row h-100">
            <div className="col-12 image-container p-0">
              <BackgroundImage
                fluid={
                  page.acf.header_image_projects.localFile.childImageSharp.fluid
                }
              />
            </div>
            <div className="container">
              <div className="col-12 col-lg-6">
                <h1>{page.title}</h1>
              </div>
            </div>
          </div>
        </BannerDefault>
        <BreadCrumbBar>
          <div className="row m-0">
            <Breadcrumb
              crumbs={crumbs}
              crumbSeparator="-"
              crumbLabel={page.title}
            />
          </div>
        </BreadCrumbBar>
        <div className="empty-space-100" />
        <section className="projects-row">
          <div className="container">
            <div className="row m-0">
              <TitleDefault>
                <span>{page.acf.title_projects}</span>
                <br />
                <strong>{page.acf.subtitle_projects}</strong>
              </TitleDefault>
              <div className="col-12 p-0">
                <div className="filters">
                  {_.map(
                    categories.edges,
                    ({ node: { wordpress_id: id, name } }) => {
                      const filterActive =
                        _.indexOf(filters.selected, id) !== -1

                      return (
                        <button
                          key={id}
                          className={`project-page-filters-label${
                            filterActive
                              ? ` project-page-filters-label-active`
                              : ``
                          }`}
                          type="button"
                          onClick={() => {
                            let newSelected = filters.selected
                            const index = _.indexOf(newSelected, id)

                            if (index === -1) newSelected.push(id)
                            else
                              newSelected = _.filter(
                                newSelected,
                                (select, newIndex) => newIndex !== index
                              )

                            return setFilters({
                              ...filters,
                              ...{ selected: newSelected },
                            })
                          }}
                        >
                          {name}
                        </button>
                      )
                    }
                  )}
                </div>

                <Projects categoryIds={filters.selected} limit={9} />
              </div>
            </div>
          </div>
          <div className="empty-space-100" />
        </section>
      </div>
    </Layout>
  )
}

export default ProjectenPage

export const ProjectenQuery = graphql`
  {
    wordpressPage(wordpress_id: { eq: 96 }) {
      title
      acf {
        header_image_projects {
          localFile {
            childImageSharp {
              fluid(quality: 100, maxWidth: 2000) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
        title_projects
        subtitle_projects
      }
      yoast_meta {
        name
        content
        property
      }
    }
    allWordpressCategory(
      sort: { fields: name, order: DESC }
      filter: { wordpress_id: { nin: 9, ne: 2 } }
    ) {
      edges {
        node {
          slug
          name
          wordpress_id
        }
      }
    }
  }
`
